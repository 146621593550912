.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Container {
  padding: 14px;
  display: flex;
  align-items: center; 
  justify-content: start;
  flex-direction: column;
  max-width: 1024px;
}

.Step {
  width: 100%;
  background: lightgreen;
  border-radius: 4px;
  height: 50px;
  align-items: center;
  justify-content: start;
  display: flex;
  font-size: 13pt;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 10px;
}

.TopHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
}

.numberId {
  background-color: lightgray;
  width: fit-content;
  font-size: 8pt;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
  border-radius: 30px;
  color: gray;
  /* font-style: italic; */
  justify-content: center;
  align-items: center;
  display:flex;
}

.NumberValue {
  color:black;
  font-style: normal;
}

.ComboBoxContainer {
  /* border-radius: 30px; */
  margin:4px;
  display:flex;
  flex-direction: column;
  align-self: start;
  text-align: left;
}

.ComboBox {
  background-color:mintcream;
  height: 30px;
  padding: 5px;
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.SelectLabel {
  color: rgb(121, 121, 121);
  /* font-style: italic; */
  font-size: small;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: mintcream !important;
  border: 1px solid #d9d9d9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  text-align: left !important;
  padding-left: 10px !important;
} */